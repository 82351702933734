* {
  font-family: multi-display, sans-serif;
  box-sizing: inherit;
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  padding-top: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000033;
  color: white;
  text-align: center;
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  display: block;
}

div {
  display: block;
}

h1 {
  margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 1.5rem;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-size: 2rem;
    line-height: 1.25;
    margin: 0.67em 0;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.5rem;
}

section {
  display: block;
}

.main {
  display: grid;
  margin-left: auto;
  margin-right: auto;
}

.phone-pink-background {
  width: 100%;
}

.info-crop {
  height: 600px;
  object-fit: cover;
}

.big-heading {
  font-size: 2.625rem;
  margin-top: 0;
}

.bodyText {
  font-size: 1.125rem;
}

.info-section {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.info-div {
  max-width: 90rem;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto; 
}

.info-header {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1.5rem;
}

.info-text {
  display: grid;
  gap: 1.5rem;
}

.info-heading {
  display: grid;
  gap: 0.5rem;
  max-width: 54rem;
}

.info-paragraph {
  font-size: 1rem;
  line-height: 1.5;
  white-space: pre-wrap;
  max-width: 40rem;
}

.info-interactives {
  display: flex;
  justify-content: center;
}

.info-image {
  width: 100%;
}

.heading {
  margin: 0;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.1;
}

.heading b {
  color: #C9327B;
    font-weight: inherit;
    font-style: normal;
    padding-bottom: 0.1em;
    margin-bottom: -0.1em;
}

.about-button {
    align-items: center;
    border: var(--theme-ui-colors-grey-60,#635e69);
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    line-height: 1;
    text-decoration: none;
    background: #004baf;
    border: 1px solid #004baf;
    color: var(--theme-ui-colors-white,#ffffff);
    font-weight: 600;
    border-radius: 8px;
    font-size: 1.125rem;
    min-height: 3rem;
    min-width: 3rem;
    padding: 0.5rem 1.25rem;
    background: #0026b9;
    border-color: #0026b9;
    border-radius: 8px;
    align-self: center;
    margin-top: auto;
}

.philosophy-link {
    align-items: center;
    border: var(--theme-ui-colors-grey-60,#635e69);
    border-radius: 6px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    line-height: 1;
    text-decoration: none;
    background: #004baf;
    border: 1px solid #004baf;
    color: var(--theme-ui-colors-white,#ffffff);
    font-weight: 600;
    border-radius: 8px;
    font-size: 1.125rem;
    min-height: 3rem;
    min-width: 3rem;
    padding: 0.5rem 1.25rem;
    background: #0026b9;
    border-color: #0026b9;
    border-radius: 8px;
    align-self: center;
    margin-top: auto;
    background: transparent;
    border-color: transparent;
    margin-top: 1.25rem;
    color: #0056f0;
    text-decoration: underline;
    text-decoration-color: #0026b9;
    text-underline-offset: 2px;
}

@media (min-width: 550px) {
  .info-section {
    padding-top: 5.25rem;
    padding-bottom: 5.25rem;
  }  

  .info-div {
    width: 90%;
  }

  .info-paragraph {
    font-size: 1.125rem;
  }

  .info-interactives {
    flex-direction: row;
  }

  .heading {
    font-size: 2.625rem;
  }

  .philosophy-link {
    margin-left: 1.25rem;
    margin-top: 0;
  }
}

@media (min-width: 1000px) {
  .info-header {
    flex-direction: row;
    text-align: left;
    gap: 3rem;
    align-items: center;
  }

  .row-reverse {
    flex-direction: row-reverse;
  }

  .info-text {
    max-width: none;
    width: 50%;
    justify-items: flex-start;
  }

  .info-image {
    width: 50%;
    max-width: none;
  }
}

.navUl {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #000022;
  padding-left: 10px;
  z-index: 1;
}

.navLi {
  float: left;
}

.navLi a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 18px;
  height: 22px;
}

.navLi a:hover {
  color: #CCCCCC;
}

#logo {
  padding: 14px 16px;
  height: 22px;
}

.main-heading {
  font-family: multi-display,sans-serif;
  font-weight: 700;
  font-style: normal;
  color: black;
  text-align: center;
  font-size: 36px;
}

#main-section {
  background-color: white;
  height: 80%
}

.foodWinPromo {
  border-radius: 1.25rem;
  width: 100%;
  z-index: -1;
}

img {
  display: block;
  transition: transform 0.4s;
  border-radius: 1.25rem;
}

img:hover {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

#footer {
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #000022;
  color: white;
  z-index: 1;
  position: relative;
}

.footer-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
}

#products {
  cursor: pointer;
}

.cvh {
  margin-top: 100px;
}

.product-tile  {
  margin-top: 3rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  height: 15rem;
  border-radius: 1.5rem;
  position: relative;
}

.app-icon {
  height: 10rem;
  width: 10rem;
  border-radius: 17.5%;
  position: absolute;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  background-color: white;
}

.app-icon-logo {
  width: 100%;
  font-size: 1.125rem;
}

.privacy-policy {
  text-align: left;
  margin-left: 20rem;
  margin-right: 20rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-color: white;
  color: black;
  border-radius: 3rem;
  padding: 5rem;
  font-size: 1.25rem;
}

@media (max-width: 1000px) {
  .privacy-policy {
    text-align: left;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    background-color: white;
    color: black;
    border-radius: 2rem;
    padding: 2rem;
    font-size: 1rem;
  }
}